<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="logotypes/doc_36.svg"
            headerText="Setup Your Data Stream Collect"
            @continue="continueForm"
            @reset="resetForm"
            ref="tabbedForm"
            @finish="createCollect"
            @input="onFormInput"
            :details="details"
            :dirty="dirty"
            :fetched="fetched"
            :update="updateCollect"
            :update-redirect="updateRedirect"
        >
            <template v-slot:step-1-feedback>
                <b-row>
                    <b-col>
                        <Feedback
                            v-if="feedbackError"
                            :state="feedbackError.state"
                            :invalid="feedbackError.message"
                        ></Feedback>
                    </b-col>
                </b-row>
            </template>
            <template v-slot:step-1-footer="{step}">
                <b-row v-if="!details">
                    <b-col>
                        <Feedback
                            :state="step.checked ? !step.invalid : null"
                            invalid="You need to finish this step before accessing the next one"
                            valid="This step is complete"
                        ></Feedback>
                        <b-button
                            @click="createCollect(step)"
                            :disabled="step.checked && step.invalid"
                            variant="primary"
                            :id="`${formId}-finish`"
                        >
                            <i class="icon-check"></i>
                            <span> Create</span>
                        </b-button>
                    </b-col>
                </b-row>
            </template>
            <template v-slot:step-2-footer>
                <b-row>
                    <b-col></b-col>
                </b-row>
            </template>
        </wit-tabbed-form>

        <Loading :loading="$store.state.loading.collecting" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, requiredIf} from 'vuelidate/lib/validators'
import {mapActions, mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {collectMixin} from '@/mixins/collectMixin'
import {formMixin} from '@/mixins/formMixin'
import {filterDirty} from '@/shared/filterDirty.js'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        Feedback,
        Loading,
        WitTabbedForm,
    },
    data() {
        return {
            collectForm: {
                name: '',
                clientIp: 'FULL',
                treeselect: ['clientRegion', 'clientCountry', 'clientCity', 'clientCityLatLong'],
            },
            currentCollectForm: {},
            falseLocation: {
                clientRegion: false,
                clientCountry: false,
                clientCity: false,
                clientCityLatLong: false,
            },
            feedbackError: null,
            collectCreated: false,
        }
    },
    mixins: [collectMixin, formMixin, validationMixin],
    validations: {
        collectForm: {
            name: {required},
            clientIp: {required},
            treeselect: {},
        },
    },
    created() {
        if (!this.details) {
            const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
            const formSessionId = formSessionMap[this.$route.path].id

            this.$gtm.trackEvent({
                event: 'create_resource_start',
                action: 'create',
                value: formSessionId,
            })
        } else {
            this.fetchCollect()
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            loading: 'loading/state',
        }),
        details() {
            return Boolean(this.$route.params.id)
        },
        cannotContinue() {
            return this.details
        },
        formId() {
            return this.details ? 'datastream-create-form' : 'datastream-details-form'
        },
        steps() {
            return [
                {
                    name: 'Collect Settings',
                    invalid: this.$v.$invalid,
                    customToggleInvalid: this.collectCreated && !this.details,
                    inputs: [
                        {
                            name: 'name',
                            value: this.$v.collectForm.name.$model,
                            model: this.$v.collectForm.name,
                            type: 'text',
                            inputLabel: 'Collect name',
                            placeholder: 'Enter your collect name',
                            invalid: 'This field is required',
                            valid: 'Name is valid',
                        },
                        {
                            name: 'client-ip',
                            value: this.$v.collectForm.clientIp.$model,
                            model: this.$v.collectForm.clientIp,
                            type: 'radio',
                            options: [
                                {
                                    value: 'FULL',
                                    label: 'Full',
                                },
                                {
                                    value: 'MASKED',
                                    label: 'Masked',
                                },
                                {
                                    value: 'NONE',
                                    label: 'None',
                                },
                            ],
                            inputLabel: 'Client IP',
                            isAdvanced: true,
                        },
                        {
                            name: 'client-location',
                            value: this.$v.collectForm.treeselect.$model,
                            model: this.$v.collectForm.treeselect,
                            type: 'treeselect',
                            options: [
                                {
                                    id: 'clientRegion',
                                    label: 'Region',
                                },
                                {
                                    id: 'clientCountry',
                                    label: 'Country',
                                },
                                {
                                    id: 'clientCity',
                                    label: 'City',
                                },
                                {
                                    id: 'clientCityLatLong',
                                    label: 'Latitude and Longitude',
                                },
                            ],
                            inputLabel: 'Client location',
                            placeholder: 'Choose fields',
                            isAdvanced: true,
                        },
                    ],
                },
                {
                    name: 'Stream instructions',
                    invalid: this.$v.$invalid,
                    customToggleInvalid: !this.collectCreated && !this.details,
                    inputs: [
                        {
                            name: 'stream-url',
                            value: this.collect
                                ? `${process.env.VUE_APP_COLLECTOR_HOST}/custom/${this.collect.id}`
                                : '',
                            type: 'copy',
                            inputLabel: 'Collector URL',
                        },
                    ],
                },
            ].filter(Boolean)
        },
        dirty() {
            const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
            return Object.keys(collectFormDirty).length > 0
        },
    },
    methods: {
        async fetchCollect() {
            this.$store.commit('loading/PROCESSING', `Fetching...`)

            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/collect/custom/${this.$route.params.id}`
            )
            this.collect = response.data.data

            this.collectForm = {
                name: this.collect.name,
                clientIp: this.collect.settings.clientIp,
                treeselect: Object.keys(this.falseLocation)
                    .map(key => (this.collect.settings[key] ? key : null))
                    .filter(Boolean),
            }
            this.currentCollectForm = cloneDeep(this.collectForm)
            this.fetched = true

            this.$store.commit('loading/PROCESSED')
        },

        async updateCollect() {
            if (!this.$v.$invalid) {
                const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
                if (Object.keys(collectFormDirty).length > 0) {
                    const trueLocation = this.collectForm.treeselect.reduce((acc, curr) => ({...acc, [curr]: true}), {})

                    const data = {
                        name: collectFormDirty.name ? collectFormDirty.name : undefined,
                        settings: {
                            clientIp: this.collectForm.clientIp,
                            ...this.falseLocation,
                            ...trueLocation,
                        },
                    }

                    this.$store.commit('loading/PROCESSING', `Updating...`)
                    try {
                        await this.axios.put(
                            `${process.env.VUE_APP_NODE_API_HOST}/collect/custom/${this.$route.params.id}`,
                            data
                        )

                        this.feedbackError = {}
                        this.currentCollectForm = cloneDeep(this.collectForm)
                        this.$forceUpdate()
                        this.$v.$reset()

                        this.$store.commit('loading/PROCESSED')
                    } catch (exception) {
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                        this.$errorHandler.report(exception, 'Could not update form')
                    }
                }
            }
        },

        async createCollect(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                const trueLocation = this.collectForm.treeselect.reduce((acc, curr) => ({...acc, [curr]: true}), {})

                const data = {
                    name: this.collectForm.name,
                    settings: {
                        clientIp: this.collectForm.clientIp,
                        ...this.falseLocation,
                        ...trueLocation,
                    },
                }

                this.$store.commit('loading/PROCESSING', `Creating your Collect...`)
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/collect/custom`, data)
                    .then(response => {
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {}
                        this.resetForm()

                        const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                        const formSessionId = formSessionMap[this.$route.path].id

                        this.$gtm.trackEvent({
                            event: 'create_resource_finish',
                            action: 'finish',
                            value: formSessionId,
                        })

                        formSessionMap[this.$route.path] = null
                        localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                        this.collectCreated = true
                        this.collect = response.data.data

                        step.nextPage()
                    })
                    .catch(exception => {
                        this.$errorHandler.report(exception, 'Could not create form')
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                    })
            }
        },
    },
}
</script>

<style lang="scss"></style>
